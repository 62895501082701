$yellow: #fab41a;
$green: #076024;
$light-yellow: lighten($yellow, 20%);
$yellow-disabled: lighten($yellow, 30%);
$orange: #de901a;
$dark-orange: #de6f1a;
$brick: #de3f1a;
$light-brick: lighten($brick, 20%);
$brick-disabled: lighten($brick, 30%);
$brown: #8b260f;
$black: #000c00; // #12141d ?
$blue: #0053b3;
$coaching-blue: #3223e2;
$purple: #8c64fa;
$light-purple: lighten($purple, 20%);
$purple-disabled: lighten($purple, 30%);
$light-purple: lighten($purple, 20%);
$dark-grey: #575757; // darken 20 grey
$grey: #6d6d6d;
$light-grey: #8a8a8a; // lighten 20 grey
$white: #fefefe;
$secondary: #101928;
$tertiary: #244168;
$grey-secondary: #b2b7bd;
$light-blue: #6595cb;
$off-white: #fffcf5;
$new-background: #010c1a;
$new-orange: #c78f00;
$secondary-light: #233857;
