/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/a11y-prebuilt.css';
@import './assets/styles/global/colors.scss';

html,
body {
  min-height: 100%;
  overflow-x: hidden; // For mobile devices
}

body {
  margin: 0;
  font-size: 20px;

  & * {
    font-family: 'poppins', 'arial', sans-serif;

    box-sizing: content-box;
  }

  background-color: $black !important;
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

@font-face {
  font-family: poppins;
  src: url(assets/fonts/Poppins-Regular.ttf) format('opentype');
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $yellow;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $yellow;
}
